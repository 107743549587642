var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-auto" },
    [
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.channel_platform"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(
          _vm._s(_vm.$t("pages.consumers.cart.channel_platform_description_1"))
        ),
      ]),
      _c("ValidationProvider", {
        staticClass: "mb-8 ml-4",
        attrs: { tag: "div", rules: { required: true, min: 1 } },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "p",
                  { staticClass: "font-bold text-red-600 mb-2 text-xs" },
                  [_vm._v(_vm._s(errors[0]))]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "overflow-hidden border border-gray-200 rounded",
                  },
                  [
                    _c(
                      "table",
                      { staticClass: "divide-y divide-gray-200 w-full" },
                      [
                        _c(
                          "tbody",
                          { staticClass: "bg-white divide-y divide-gray-200" },
                          [
                            _vm.allChannels.length === 0
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "h-10",
                                      attrs: { colspan: "3" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "w-full block text-center align-middle",
                                        },
                                        [
                                          _vm._v(
                                            "please register channels and platforms"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._l(
                                  _vm.allChannels,
                                  function (channel, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _c(
                                          "div",
                                          { staticClass: "px-3 py-2" },
                                          [
                                            _c(
                                              "FlCheckbox",
                                              {
                                                attrs: {
                                                  trueValue: {
                                                    uuid: channel.uuid,
                                                  },
                                                  compareValues: ["uuid"],
                                                },
                                                model: {
                                                  value: _vm.channels,
                                                  callback: function ($$v) {
                                                    _vm.channels = $$v
                                                  },
                                                  expression: "channels",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(channel.name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c("td", [
                                        _c("p", [
                                          _c(
                                            "a",
                                            { attrs: { href: channel.url } },
                                            [_vm._v(_vm._s(channel.url))]
                                          ),
                                        ]),
                                      ]),
                                      _c("td"),
                                    ])
                                  }
                                ),
                            !_vm.newChannelDisabled
                              ? [
                                  _c(
                                    "ValidationObserver",
                                    {
                                      ref: "newChannelObserver",
                                      attrs: { tag: "tr" },
                                    },
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c("FlInlineInput", {
                                            attrs: {
                                              sublabel: _vm.$t(
                                                "commons.fields.name"
                                              ),
                                              rules: {
                                                required: _vm.isCreatingChannel,
                                                max: 50,
                                              },
                                              disabled: _vm.newChannelDisabled,
                                              placeholder: "channel name",
                                            },
                                            model: {
                                              value: _vm.newChannel.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newChannel,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "newChannel.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("FlInlineInput", {
                                            attrs: {
                                              sublabel:
                                                _vm.$t("commons.fields.url"),
                                              rules: {
                                                required: _vm.isCreatingChannel,
                                                url: true,
                                              },
                                              disabled: _vm.newChannelDisabled,
                                              placeholder: "url of the channel",
                                            },
                                            model: {
                                              value: _vm.newChannel.url,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.newChannel,
                                                  "url",
                                                  $$v
                                                )
                                              },
                                              expression: "newChannel.url",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("FlButton", {
                                            attrs: {
                                              label: "ADD",
                                              hasArrow: false,
                                              disabled: _vm.newChannelDisabled,
                                            },
                                            on: { click: _vm.addChannel },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                    errors.length
                      ? _c(
                          "div",
                          _vm._l(errors, function (error, index) {
                            return _c(
                              "p",
                              {
                                key: index,
                                staticClass:
                                  "font-bold text-red-600 mb-2 text-xxs",
                              },
                              [_vm._v(_vm._s(error))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }