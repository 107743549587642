var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.validationRef
    ? _c(
        "ValidationObserver",
        {
          ref: _vm.validationRef,
          staticClass: "w-full",
          attrs: { tag: "div" },
        },
        [_c("keep-alive", [_vm.active ? _vm._t("default") : _vm._e()], 2)],
        1
      )
    : _c(
        "div",
        { staticClass: "w-full" },
        [_c("keep-alive", [_vm.active ? _vm._t("default") : _vm._e()], 2)],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }