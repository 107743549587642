var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto mb-6" },
    [
      _c(
        "p",
        { staticClass: "text-md font-bold underline decoration-2 mb-6" },
        [_vm._v(_vm._s(_vm.$t("pages.consumers.cart.cart-explanation")))]
      ),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.profile.saved_conditions"))),
      ]),
      _c("div", { staticClass: "ml-4" }, [
        _c("p", { staticClass: "mb-6 text-sm" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("pages.consumers.cart.saved_conditions_description_1")
              ) +
              " "
          ),
        ]),
        _vm.quoteSets.length > 0
          ? _c("div", { staticClass: "mb-8" }, [
              _c("div", { staticClass: "w-full md:w-2/3 xl:w-1/2 mx-auto" }, [
                _c(
                  "table",
                  { staticClass: "w-full" },
                  _vm._l(_vm.quoteSets, function (quoteSet, index) {
                    return _c("tr", { key: index }, [
                      _c("td", { staticClass: "p-2 px-2 text-right" }, [
                        _c("span", [_vm._v(_vm._s(quoteSet.set_name))]),
                        _vm.isQuoteOld(quoteSet)
                          ? _c("span", [_vm._v(" (old)")])
                          : _vm._e(),
                      ]),
                      _c(
                        "td",
                        {
                          staticClass: "text-right p-2",
                          attrs: { width: "290" },
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "px-2 py-1 bg-base-yellow h-10 capitalize mr-2 rounded",
                              on: {
                                click: function ($event) {
                                  return _vm.setSelectQuote(quoteSet)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("pages.consumers.cart.apply")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "px-2 py-1 bg-light-yellow h-10 capitalize mr-2 rounded",
                              on: {
                                click: function ($event) {
                                  return _vm.redirectQuoteView(quoteSet)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("pages.consumers.cart.check")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "text-white px-2 py-1 bg-brown-grey h-10 capitalize rounded",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteQuoteSet(quoteSet)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.consumers.cart.delete")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ])
          : _c("div", { staticClass: "mb-8" }, [
              _c("p", { staticClass: "text-center" }, [
                _vm._v("no saved conditions found"),
              ]),
            ]),
      ]),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.commons.quotes.timeline"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.timeline_description_1"))),
      ]),
      _c("div", { staticClass: "mb-10 ml-4" }, [
        _c(
          "div",
          { staticClass: "grid grid-cols-12 mb-4" },
          [
            _c("p", { staticClass: "col-start-1 col-span-3" }, [
              _vm._v(
                _vm._s(_vm.$t("commons.unit.today")) +
                  " (" +
                  _vm._s(_vm.$d(new Date(), "short")) +
                  ")"
              ),
            ]),
            _c("p", {
              staticClass:
                "col-start-1 col-span-1 h-14 border-r border-gray-500",
            }),
            _c("p", {
              staticClass: "col-span-3 h-1/2 border-b border-gray-500",
            }),
            _c(
              "div",
              { staticClass: "col-span-3 flex flex-col justify-center pl-2" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.estimateDuration,
                        expression: "estimateDuration",
                      },
                    ],
                    staticClass: "w-full bg-white input_text text-sm",
                    attrs: { name: "estimate_at" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.estimateDuration = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.estimateDurationChanged($event)
                        },
                      ],
                    },
                  },
                  _vm._l(_vm.estimatedDate, function (option, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: option } },
                      [_vm._v(" " + _vm._s(option) + " ")]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "col-span-5 flex flex-col justify-center" },
              [
                _c("span", { staticClass: "pl-2 text-sm" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "pages.consumers.cart.condition_dates.issue_quote_duration"
                      )
                    )
                  ),
                ]),
              ]
            ),
            _c("FlDate2", {
              staticClass: "col-start-1 col-span-3",
              attrs: {
                fieldId: "estimate_at",
                label: _vm.$t("pages.consumers.cart.estimated_date"),
                fromDate: _vm.minEstimateAt,
                rules: { required: true },
              },
              model: {
                value: _vm.estimateAt,
                callback: function ($$v) {
                  _vm.estimateAt = $$v
                },
                expression: "estimateAt",
              },
            }),
            _c("p", {
              staticClass:
                "col-start-1 col-span-1 h-14 border-r border-gray-500",
            }),
            _c("p", {
              staticClass: "col-span-3 h-1/2 border-b border-gray-500",
            }),
            _vm._m(0),
            _c("FlDate2", {
              staticClass: "col-start-1 col-span-3",
              attrs: {
                fieldId: "delivery_at",
                label: _vm.$t("pages.consumers.cart.delivery_date"),
                fromDate: _vm.minDeliveryAt,
                rules: {
                  required: true,
                  dateAfter: { baseDate: _vm.quote.estimate_at },
                },
              },
              model: {
                value: _vm.deliveryAt,
                callback: function ($$v) {
                  _vm.deliveryAt = $$v
                },
                expression: "deliveryAt",
              },
            }),
            _c("p", {
              staticClass:
                "col-start-1 col-span-1 h-14 border-r border-gray-500",
            }),
            _c("p", {
              staticClass: "col-span-3 h-1/2 border-b border-gray-500",
            }),
            _vm._m(1),
            _c("FlDate2", {
              staticClass: "col-start-1 col-span-3",
              attrs: {
                fieldId: "started_at",
                label: _vm.$t("pages.consumers.cart.start_at"),
                fromDate: _vm.minLicenseStart,
                rules: {
                  required: true,
                  dateAfter: { baseDate: _vm.quote.delivery_at },
                },
                fieldName: "Start Date",
              },
              model: {
                value: _vm.licenseStartAt,
                callback: function ($$v) {
                  _vm.licenseStartAt = $$v
                },
                expression: "licenseStartAt",
              },
            }),
            _c("p", {
              staticClass:
                "col-start-1 col-span-1 h-24 border-r border-gray-500",
            }),
            _c("p", {
              staticClass: "col-span-3 h-1/2 border-b border-gray-500",
            }),
            _c("FlSelect2", {
              staticClass: "col-span-2 pl-2",
              attrs: {
                label: _vm.$t("pages.consumers.cart.distribution_delay"),
                options: _vm.distributionDelay,
              },
              model: {
                value: _vm.quote.latest_distribution_start_duration,
                callback: function ($$v) {
                  _vm.$set(_vm.quote, "latest_distribution_start_duration", $$v)
                },
                expression: "quote.latest_distribution_start_duration",
              },
            }),
            _c(
              "span",
              {
                staticClass:
                  "col-span-6 flex flex-col justify-center text-sm ml-2",
              },
              [
                _vm._v(
                  " Limit allowed to be delayed for actual beginning of distribution compared to planned distribution begin date. "
                ),
              ]
            ),
            _c("p", {
              staticClass:
                "col-start-1 col-span-1 h-20 border-r border-gray-500",
            }),
            _c("p", {
              staticClass: "col-span-3 h-1/2 border-b border-gray-500",
            }),
            _vm._m(2),
            _c(
              "div",
              { staticClass: "col-span-2 flex flex-col justify-center pl-2" },
              [
                _c("FlInput", {
                  attrs: {
                    type: "number",
                    rules: { required: !_vm.intentionallyShort, min_value: 0 },
                    sublabel: _vm.$t("pages.consumers.cart.year"),
                  },
                  model: {
                    value: _vm.licenseYear,
                    callback: function ($$v) {
                      _vm.licenseYear = _vm._n($$v)
                    },
                    expression: "licenseYear",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2 flex flex-col justify-center pl-2" },
              [
                _c("FlInput", {
                  attrs: {
                    type: "number",
                    rules: _vm.licenseMonthRules,
                    sublabel: _vm.$t("pages.consumers.cart.month"),
                  },
                  model: {
                    value: _vm.licenseMonth,
                    callback: function ($$v) {
                      _vm.licenseMonth = _vm._n($$v)
                    },
                    expression: "licenseMonth",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-span-2 pl-2 text-xs flex flex-col justify-center",
              },
              [
                _c(
                  "FlCheckbox",
                  {
                    model: {
                      value: _vm.intentionallyShort,
                      callback: function ($$v) {
                        _vm.intentionallyShort = $$v
                      },
                      expression: "intentionallyShort",
                    },
                  },
                  [_vm._v("Less than a month for events / screening.")]
                ),
              ],
              1
            ),
            _c("FlDate2", {
              staticClass: "col-start-1 col-span-3",
              attrs: {
                fieldId: "ended_at",
                label: _vm.$t("pages.consumers.cart.ended_at"),
                fromDate: _vm.licenseStartAt,
                rules: {
                  required: true,
                  dateAfter: { baseDate: _vm.licenseStartAtCanOverlap },
                },
              },
              model: {
                value: _vm.licenseEndAt,
                callback: function ($$v) {
                  _vm.licenseEndAt = $$v
                },
                expression: "licenseEndAt",
              },
            }),
          ],
          1
        ),
      ]),
      _c("NoticeModal", {
        attrs: {
          modalName: "loadQuoteName",
          contents: _vm.noticeMessage,
          buttons: ["OK"],
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "noticeModal",
          contents: _vm.noticeMessage,
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-3 flex flex-col justify-center" },
      [_c("span", { staticClass: "pl-2 text-sm" }, [_vm._v("minimum 1 week")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-3 flex flex-col justify-center" },
      [_c("span", { staticClass: "pl-2 text-sm" }, [_vm._v("minimum 1 week")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-2 flex flex-col justify-center" },
      [_c("span", { staticClass: "pl-2 text-sm" }, [_vm._v("license period")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }