var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto mb-6" }, [
    _c("h2", { staticClass: "mb-4" }, [
      _vm._v(_vm._s(_vm.$t("pages.consumers.cart.required_videos"))),
    ]),
    _c(
      "div",
      { staticClass: "ml-4 mb-8" },
      [
        _c("FlAccordion", {
          attrs: {
            defaultOpen: true,
            enableTitleBarToggle: false,
            enableRightButtonToggle: false,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function (ref) {
                var toggleState = ref.toggleState
                return [
                  _c(
                    "FlCheckbox",
                    {
                      attrs: { trueValue: _vm.mainVideo, disabled: true },
                      on: {
                        input: function ($event) {
                          return toggleState($event)
                        },
                      },
                      model: {
                        value: _vm.mutableValue.formats,
                        callback: function ($$v) {
                          _vm.$set(_vm.mutableValue, "formats", $$v)
                        },
                        expression: "mutableValue.formats",
                      },
                    },
                    [
                      _c("span", { staticClass: "font-bold" }, [
                        _vm._v("main video"),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: "default",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("h3", { staticClass: "mb-2" }, [
                        _vm._v("video format"),
                      ]),
                      _c("VideoFormat", {
                        staticClass: "mb-4",
                        attrs: { enableDcpFormat: true },
                        on: {
                          dcp: function ($event) {
                            return _vm.mainMovieDcpChange($event)
                          },
                        },
                        model: {
                          value: _vm.mainVideo,
                          callback: function ($$v) {
                            _vm.mainVideo = $$v
                          },
                          expression: "mainVideo",
                        },
                      }),
                      _c("h3", { staticClass: "mb-2" }, [_vm._v("subtitles")]),
                      _c("QuoteLanguage", {
                        staticClass: "mb-4",
                        attrs: {
                          title: "subtitles",
                          video_type: 1,
                          is_subtitle: true,
                          is_voiceover: false,
                          disableFormats: _vm.mainVideo.is_dcp,
                        },
                        model: {
                          value: _vm.mutableValue.languages,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "languages", $$v)
                          },
                          expression: "mutableValue.languages",
                        },
                      }),
                      _c("h3", { staticClass: "mb-2" }, [_vm._v("dubbs")]),
                      _c("QuoteLanguage", {
                        attrs: {
                          title: "dubbs",
                          video_type: 1,
                          is_subtitle: false,
                          is_voiceover: true,
                          disableFormats: _vm.mainVideo.is_dcp,
                        },
                        model: {
                          value: _vm.mutableValue.languages,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "languages", $$v)
                          },
                          expression: "mutableValue.languages",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ml-4 mb-8" },
      [
        _c(
          "FlAccordion",
          {
            attrs: {
              defaultOpen: true,
              enableTitleBarToggle: false,
              enableRightButtonToggle: false,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (ref) {
                  var toggleState = ref.toggleState
                  return [
                    _c(
                      "FlCheckbox",
                      {
                        on: {
                          input: function ($event) {
                            toggleState($event)
                            _vm.initTrailer($event)
                          },
                        },
                        model: {
                          value: _vm.mutableValue.needs_trailer,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "needs_trailer", $$v)
                          },
                          expression: "mutableValue.needs_trailer",
                        },
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("trailer video"),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            [
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isTrailerSameFormat,
                        callback: function ($$v) {
                          _vm.isTrailerSameFormat = $$v
                        },
                        expression: "isTrailerSameFormat",
                      },
                    },
                    [_vm._v(" Trailer must follow main movie format ")]
                  ),
                ],
                1
              ),
              _vm.mutableValue.needs_trailer && !_vm.isTrailerSameFormat
                ? _c("VideoFormat", {
                    staticClass: "mb-4",
                    model: {
                      value: _vm.trailer,
                      callback: function ($$v) {
                        _vm.trailer = $$v
                      },
                      expression: "trailer",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isTrailerSameLanguage,
                        callback: function ($$v) {
                          _vm.isTrailerSameLanguage = $$v
                        },
                        expression: "isTrailerSameLanguage",
                      },
                    },
                    [_vm._v(" Trailer must follow main movie language ")]
                  ),
                  _vm.mutableValue.needs_trailer && !_vm.isTrailerSameLanguage
                    ? [
                        _c("h3", { staticClass: "mb-2" }, [
                          _vm._v("subtitles"),
                        ]),
                        _c("QuoteLanguage", {
                          staticClass: "mb-4",
                          attrs: {
                            title: "subtitles",
                            video_type: 2,
                            is_subtitle: true,
                            is_voiceover: false,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                        _c("h3", { staticClass: "mb-2" }, [_vm._v("dubbs")]),
                        _c("QuoteLanguage", {
                          attrs: {
                            title: "dubbs",
                            video_type: 2,
                            is_subtitle: false,
                            is_voiceover: true,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ml-4 mb-8" },
      [
        _c(
          "FlAccordion",
          {
            attrs: {
              defaultOpen: _vm.mutableValue.needs_interview,
              enableTitleBarToggle: false,
              enableRightButtonToggle: false,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (ref) {
                  var toggleState = ref.toggleState
                  return [
                    _c(
                      "FlCheckbox",
                      {
                        on: {
                          input: function ($event) {
                            toggleState($event)
                            _vm.initInterview($event)
                          },
                        },
                        model: {
                          value: _vm.mutableValue.needs_interview,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "needs_interview", $$v)
                          },
                          expression: "mutableValue.needs_interview",
                        },
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("interview of cast & crew members"),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            [
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isInterviewSameFormat,
                        callback: function ($$v) {
                          _vm.isInterviewSameFormat = $$v
                        },
                        expression: "isInterviewSameFormat",
                      },
                    },
                    [_vm._v(" Interview must follow main movie format ")]
                  ),
                ],
                1
              ),
              _vm.mutableValue.needs_interview && !_vm.isInterviewSameFormat
                ? _c("VideoFormat", {
                    staticClass: "mb-4",
                    model: {
                      value: _vm.interview,
                      callback: function ($$v) {
                        _vm.interview = $$v
                      },
                      expression: "interview",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isInterviewSameLanguage,
                        callback: function ($$v) {
                          _vm.isInterviewSameLanguage = $$v
                        },
                        expression: "isInterviewSameLanguage",
                      },
                    },
                    [_vm._v(" Interview must follow main movie language ")]
                  ),
                  _vm.mutableValue.needs_interview &&
                  !_vm.isInterviewSameLanguage
                    ? [
                        _c("h3", { staticClass: "mb-2" }, [
                          _vm._v("subtitles"),
                        ]),
                        _c("QuoteLanguage", {
                          staticClass: "mb-4",
                          attrs: {
                            title: "subtitles",
                            video_type: 3,
                            is_subtitle: true,
                            is_voiceover: false,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                        _c("h3", { staticClass: "mb-2" }, [_vm._v("dubbs")]),
                        _c("QuoteLanguage", {
                          attrs: {
                            title: "dubbs",
                            video_type: 3,
                            is_subtitle: false,
                            is_voiceover: true,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ml-4 mb-8" },
      [
        _c(
          "FlAccordion",
          {
            attrs: {
              defaultOpen: _vm.mutableValue.needs_making,
              enableTitleBarToggle: false,
              enableRightButtonToggle: false,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (ref) {
                  var toggleState = ref.toggleState
                  return [
                    _c(
                      "FlCheckbox",
                      {
                        on: {
                          input: function ($event) {
                            toggleState($event)
                            _vm.initMakingOf($event)
                          },
                        },
                        model: {
                          value: _vm.mutableValue.needs_making,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "needs_making", $$v)
                          },
                          expression: "mutableValue.needs_making",
                        },
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("making-of or behind the scenes"),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            [
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isMakingSameFormat,
                        callback: function ($$v) {
                          _vm.isMakingSameFormat = $$v
                        },
                        expression: "isMakingSameFormat",
                      },
                    },
                    [_vm._v(" Making-of must follow main movie format ")]
                  ),
                ],
                1
              ),
              _vm.mutableValue.needs_making && !_vm.isMakingSameFormat
                ? _c("VideoFormat", {
                    staticClass: "mb-4",
                    model: {
                      value: _vm.makingOf,
                      callback: function ($$v) {
                        _vm.makingOf = $$v
                      },
                      expression: "makingOf",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isMakingSameLanguage,
                        callback: function ($$v) {
                          _vm.isMakingSameLanguage = $$v
                        },
                        expression: "isMakingSameLanguage",
                      },
                    },
                    [_vm._v(" Making-of must follow main movie language ")]
                  ),
                  _vm.mutableValue.needs_making && !_vm.isMakingSameLanguage
                    ? [
                        _c("h3", { staticClass: "mb-2" }, [
                          _vm._v("subtitles"),
                        ]),
                        _c("QuoteLanguage", {
                          staticClass: "mb-4",
                          attrs: {
                            title: "subtitles",
                            video_type: 4,
                            is_subtitle: true,
                            is_voiceover: false,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                        _c("h3", { staticClass: "mb-2" }, [_vm._v("dubbs")]),
                        _c("QuoteLanguage", {
                          attrs: {
                            title: "dubbs",
                            video_type: 4,
                            is_subtitle: false,
                            is_voiceover: true,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ml-4 mb-8" },
      [
        _c(
          "FlAccordion",
          {
            attrs: {
              defaultOpen: _vm.mutableValue.needs_extended_trailer,
              enableTitleBarToggle: false,
              enableRightButtonToggle: false,
            },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function (ref) {
                  var toggleState = ref.toggleState
                  return [
                    _c(
                      "FlCheckbox",
                      {
                        on: {
                          input: function ($event) {
                            toggleState($event)
                            _vm.initExtendedTrailer($event)
                          },
                        },
                        model: {
                          value: _vm.mutableValue.needs_extended_trailer,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mutableValue,
                              "needs_extended_trailer",
                              $$v
                            )
                          },
                          expression: "mutableValue.needs_extended_trailer",
                        },
                      },
                      [
                        _c("span", { staticClass: "font-bold" }, [
                          _vm._v("Extended trailer"),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            [
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isExtendedTrailerSameFormat,
                        callback: function ($$v) {
                          _vm.isExtendedTrailerSameFormat = $$v
                        },
                        expression: "isExtendedTrailerSameFormat",
                      },
                    },
                    [_vm._v(" Extended trailer must follow main movie format ")]
                  ),
                ],
                1
              ),
              _vm.mutableValue.needs_extended_trailer &&
              !_vm.isExtendedTrailerSameFormat
                ? _c("VideoFormat", {
                    staticClass: "mb-4",
                    model: {
                      value: _vm.extendedTrailer,
                      callback: function ($$v) {
                        _vm.extendedTrailer = $$v
                      },
                      expression: "extendedTrailer",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                [
                  _c(
                    "FlCheckbox",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: _vm.mainVideo.is_dcp },
                      model: {
                        value: _vm.isExtendedTrailerSameLanguage,
                        callback: function ($$v) {
                          _vm.isExtendedTrailerSameLanguage = $$v
                        },
                        expression: "isExtendedTrailerSameLanguage",
                      },
                    },
                    [
                      _vm._v(
                        " Extended trailer must follow main movie language "
                      ),
                    ]
                  ),
                  _vm.mutableValue.needs_extended_trailer &&
                  !_vm.isExtendedTrailerSameLanguage
                    ? [
                        _c("h3", { staticClass: "mb-2" }, [
                          _vm._v("subtitles"),
                        ]),
                        _c("QuoteLanguage", {
                          staticClass: "mb-4",
                          attrs: {
                            title: "subtitles",
                            video_type: 5,
                            is_subtitle: true,
                            is_voiceover: false,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                        _c("h3", { staticClass: "mb-2" }, [_vm._v("dubbs")]),
                        _c("QuoteLanguage", {
                          attrs: {
                            title: "dubbs",
                            video_type: 5,
                            is_subtitle: false,
                            is_voiceover: true,
                          },
                          model: {
                            value: _vm.mutableValue.languages,
                            callback: function ($$v) {
                              _vm.$set(_vm.mutableValue, "languages", $$v)
                            },
                            expression: "mutableValue.languages",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
          ],
          2
        ),
      ],
      1
    ),
    _c("h2", { staticClass: "mb-4" }, [
      _vm._v(_vm._s(_vm.$t("pages.consumers.cart.message_about_materials"))),
    ]),
    _c("p", { staticClass: "mb-2 text-sm" }, [
      _vm._v(
        _vm._s(
          _vm.$t("pages.consumers.cart.special_note_cart_video_description_2")
        )
      ),
    ]),
    _c("p", { staticClass: "mb-6 text-sm" }, [
      _vm._v(
        _vm._s(
          _vm.$t("pages.consumers.cart.special_note_cart_video_description_1")
        )
      ),
    ]),
    _c(
      "div",
      { staticClass: "mb-8 ml-4" },
      [
        _c("FlTextArea", {
          attrs: { label: "message", rules: { max: _vm.maxInputMessage } },
          model: {
            value: _vm.mutableValue.message,
            callback: function ($$v) {
              _vm.$set(_vm.mutableValue, "message", $$v)
            },
            expression: "mutableValue.message",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }