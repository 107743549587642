var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full grid grid-cols-8 gap-4" },
    [
      _c(
        "div",
        { staticClass: "col-span-1" },
        [
          _vm.prefix === "screenshot" || _vm.prefix === "making_visual"
            ? _c("FlSelect2", {
                attrs: {
                  fieldId: _vm.prefix + "_count",
                  options: _vm.imageCount,
                  needsTranslation: false,
                  rules: _vm.required
                    ? { required: _vm.required, min_value: 1 }
                    : {},
                  disabled: !_vm.required,
                  label: _vm.$t("pages.consumers.cart.picture"),
                  inputKey: _vm.prefix + "_count",
                },
                model: {
                  value: _vm.mutableValue[_vm.prefix + "_count"],
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mutableValue,
                      _vm.prefix + "_count",
                      _vm._n($$v)
                    )
                  },
                  expression: "mutableValue[`${prefix}_count`]",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("FlCheckboxGroup", {
            attrs: {
              label: _vm.$t("pages.consumers.cart.format"),
              options: _vm.formatOptions,
              fieldId: _vm.prefix + "_formats",
              rules: { required: _vm.required },
              disabled: !_vm.required,
              direction: "horizontal",
              inputKey: _vm.prefix + "_formats",
              name: _vm.title + " " + _vm.$t("pages.consumers.cart.format"),
            },
            model: {
              value: _vm.mutableValue[_vm.prefix + "_formats"],
              callback: function ($$v) {
                _vm.$set(_vm.mutableValue, _vm.prefix + "_formats", $$v)
              },
              expression: "mutableValue[`${prefix}_formats`]",
            },
          }),
          _c("p", { staticClass: "block pb-2 text-xs font-bold" }, [
            _vm._v("Please check all acceptable formats."),
          ]),
        ],
        1
      ),
      _vm.hasSize
        ? [
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("FlInput", {
                  attrs: {
                    fieldId: _vm.prefix + "_size.width",
                    name: "width",
                    disabled: !_vm.required,
                    rules: {
                      integer: true,
                      min_value: 1,
                    },
                    type: "number",
                    label: _vm.$t("pages.consumers.cart.width"),
                    sublabel: "px",
                    inputKey: _vm.prefix + "_size.width",
                  },
                  model: {
                    value: _vm.mutableValue[_vm.prefix + "_size"].width,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.mutableValue[_vm.prefix + "_size"],
                        "width",
                        _vm._n($$v)
                      )
                    },
                    expression: "mutableValue[`${prefix}_size`].width",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("FlInput", {
                  attrs: {
                    fieldId: _vm.prefix + "_size.height",
                    name: "height",
                    disabled: !_vm.required,
                    rules: {
                      integer: true,
                      min_value: 1,
                    },
                    type: "number",
                    label: _vm.$t("pages.consumers.cart.height"),
                    sublabel: "px",
                    inputKey: _vm.prefix + "_size.height",
                  },
                  model: {
                    value: _vm.mutableValue[_vm.prefix + "_size"].height,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.mutableValue[_vm.prefix + "_size"],
                        "height",
                        _vm._n($$v)
                      )
                    },
                    expression: "mutableValue[`${prefix}_size`].height",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.hasLayers
        ? _c(
            "div",
            { staticClass: "col-span-1" },
            [
              _c("div", { staticClass: "w-full inline-block" }, [
                _c("span", { staticClass: "float-left text-xs -mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("pages.consumers.cart.prefer_layered"))),
                ]),
              ]),
              _c(
                "FlCheckbox",
                {
                  attrs: { inputKey: _vm.prefix + "_is_layered" },
                  model: {
                    value: _vm.mutableValue[_vm.prefix + "_is_layered"],
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.mutableValue,
                        _vm.prefix + "_is_layered",
                        $$v
                      )
                    },
                    expression: "mutableValue[`${prefix}_is_layered`]",
                  },
                },
                [
                  _c("span", { staticClass: "text-xs" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("pages.consumers.cart.image.prefer_layered")
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.hasLanguage
        ? _c(
            "div",
            { staticClass: "col-span-1" },
            [
              _c("FlSelect2", {
                staticClass: "inline text-xs",
                attrs: {
                  label: _vm.$t("commons.fields.language"),
                  options: _vm.languages,
                  inputKey: _vm.prefix + "_language",
                },
                model: {
                  value: _vm.mutableValue[_vm.prefix + "_language"],
                  callback: function ($$v) {
                    _vm.$set(_vm.mutableValue, _vm.prefix + "_language", $$v)
                  },
                  expression: "mutableValue[`${prefix}_language`]",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }