var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("div", { staticClass: "w-full mb-8" }, [
        _c(
          "ul",
          { ref: "tabs", staticClass: "flex space-y-0 space-x-8" },
          _vm._l(_vm.tabs, function (tab, index) {
            return _c("li", { key: index, staticClass: "flex-1" }, [
              _c(
                "button",
                {
                  staticClass: "w-full h-full",
                  on: {
                    click: function ($event) {
                      return _vm.changeTab(_vm.activeIndex, index)
                    },
                  },
                },
                [
                  _vm._t("header-item", null, {
                    title: tab.title,
                    selected: index === _vm.activeIndex,
                    disabled: !_vm.allowUnfinished && index > _vm.activeIndex,
                    errors: tab.errors,
                  }),
                ],
                2
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._t("default"),
      _c(
        "div",
        { staticClass: "w-full" },
        [
          _vm._t("footer", null, {
            prevBtDisabled: !_vm.hasPrevTab,
            nextBtDisabled: !_vm.hasNextTab,
            prevBtClicked: _vm.showPrevTab,
            nextBtClicked: _vm.showNextTab,
            validate: _vm.validate,
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }