var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto" },
    [
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.rights"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.rights_description_1"))),
      ]),
      _c(
        "div",
        { staticClass: "mb-8 ml-4" },
        [
          _c("ValidateParts", {
            attrs: {
              value: _vm.mutableValue.rights,
              vid: "rights",
              rules: { required: true },
              name: _vm.$t("pages.consumers.cart.rights"),
              isDisplayErrors: _vm.mutableValue.rights.length === 0,
              errorDisplaySize: "text-xs font-bold",
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "border border-brown-grey text-left relative py-1 px-3 mb-2 grid grid-cols-12 items-center",
            },
            [
              _c(
                "FlCheckbox",
                {
                  staticClass: "col-span-4",
                  on: { input: _vm.toggleAllRights },
                },
                [
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v("All rights"),
                  ]),
                ]
              ),
              _c("div", { staticClass: "col-span-1 h-10" }),
            ],
            1
          ),
          _vm._l(_vm.rightTypes, function (right, index) {
            return _c("CartRightsItem", {
              key: index,
              attrs: { data: right },
              model: {
                value: _vm.mutableValue.rights,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableValue, "rights", $$v)
                },
                expression: "mutableValue.rights",
              },
            })
          }),
        ],
        2
      ),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.territory"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.territory_description_1"))),
      ]),
      _c(
        "div",
        { staticClass: "mb-8 ml-4" },
        [
          _c(
            "FlCheckbox",
            {
              model: {
                value: _vm.mutableValue.all_countries,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableValue, "all_countries", $$v)
                },
                expression: "mutableValue.all_countries",
              },
            },
            [_vm._v(_vm._s(_vm.$t("pages.consumers.cart.select_all_area")))]
          ),
          _vm.value.all_countries
            ? _c(
                "i18n",
                {
                  staticClass: "pt-2",
                  attrs: {
                    path: "pages.consumers.cart.selection_explanation_worldwide",
                    tag: "p",
                  },
                },
                [
                  _c(
                    "b",
                    {
                      staticClass: "bg-off-yellow-lighter",
                      attrs: { place: "emphasize" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "pages.consumers.cart.select_explanation_except"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              )
            : _c(
                "i18n",
                {
                  staticClass: "pt-2",
                  attrs: {
                    path: "pages.consumers.cart.selection_explanation_normal",
                    tag: "p",
                  },
                },
                [
                  _c("b", { attrs: { place: "emphasize" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("pages.consumers.cart.select_explanation_normal")
                      )
                    ),
                  ]),
                ]
              ),
          _c("FlAreaCountry", {
            attrs: {
              rules: { required: !_vm.value.all_countries },
              preSelectArea: true,
            },
            scopedSlots: _vm._u([
              {
                key: "emptyComment",
                fn: function () {
                  return [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("pages.consumers.cart.area_select_left"))
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("pages.consumers.cart.area_check_left"))
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "span",
                      { staticClass: "my-2 p-1 text-xs bg-off-yellow-lighter" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$i18n.t("pages.commons.quotes.territory_note")
                          )
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.mutableValue.countries,
              callback: function ($$v) {
                _vm.$set(_vm.mutableValue, "countries", $$v)
              },
              expression: "mutableValue.countries",
            },
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.currency"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.currency_description_1"))),
      ]),
      _c(
        "div",
        { staticClass: "mb-8 ml-4" },
        [
          _c("ValidationProvider", {
            class: "text-left",
            attrs: {
              tag: "div",
              vid: "currency",
              rules: { required: true },
              name: _vm.$t("pages.consumers.cart.currency"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.mutableValue.currency,
                            expression: "mutableValue.currency",
                          },
                        ],
                        staticClass: "w-4/12 input_text h-10 bg-white",
                        attrs: { name: "movie_type" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.mutableValue,
                              "currency",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.currencyOption, function (option, key) {
                        return _c(
                          "option",
                          {
                            key: key,
                            domProps: {
                              value: option,
                              selected: option === _vm.value.curency,
                            },
                          },
                          [_vm._v(" " + _vm._s(option) + " ")]
                        )
                      }),
                      0
                    ),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors.length,
                            expression: "errors.length",
                          },
                        ],
                        staticClass: "text-xxs text-left text-red-600 absolute",
                      },
                      [_vm._v(_vm._s(errors[0]))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.pricing_model"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(
          _vm._s(_vm.$t("pages.consumers.cart.pricing_model_description_1"))
        ),
      ]),
      _c(
        "div",
        { staticClass: "mb-8 ml-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              vid: "pricing_type",
              rules: { required: true },
              name: _vm.$t("pages.consumers.cart.pricing_model"),
              mode: "lazy",
              slim: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors.length,
                            expression: "errors.length",
                          },
                        ],
                        staticClass: "text-xs font-bold text-left text-red-600",
                      },
                      [_vm._v(_vm._s(errors[0]))]
                    ),
                    _c("section", [
                      _c(
                        "div",
                        { staticClass: "border border-brown-grey px-3 mb-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block w-full h-10 pl-2 flex items-center cursor-pointer",
                              attrs: { for: "flatRate" },
                            },
                            [
                              _c(
                                "FlRadio",
                                {
                                  staticClass: "inline-block mr-2",
                                  attrs: {
                                    inputId: "viewerCount",
                                    inputFieldName: "flatRate",
                                    fieldValue: 1,
                                  },
                                  model: {
                                    value: _vm.mutableValue.pricing_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.mutableValue,
                                        "pricing_type",
                                        $$v
                                      )
                                    },
                                    expression: "mutableValue.pricing_type",
                                  },
                                },
                                [_vm._v("By flat rate")]
                              ),
                            ],
                            1
                          ),
                          _vm.value.pricing_type == 1
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-light-grey w-full text-left p-4 text-xs",
                                },
                                [
                                  _c("p", { staticClass: "pb-4" }, [
                                    _vm._v(
                                      "Select this if you wish to package all rights selected in a fixed price offer."
                                    ),
                                  ]),
                                  _c(
                                    "p",
                                    { staticClass: "pb-4 inline-block" },
                                    [
                                      _vm._v(
                                        "Total amount to be paied by Licensee (buyer) for single movie is:"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mr-2 bg-brown-grey font-bold py-2 px-4 rounded inline-block",
                                        },
                                        [_vm._v(_vm._s(_vm.currentCurrency))]
                                      ),
                                      _c("FlInlineInput", {
                                        class: "inline-block",
                                        attrs: {
                                          fieldId: "flat_price",
                                          rules: {
                                            required: _vm.isFlatRate,
                                            integer: true,
                                            min_value: 1,
                                          },
                                          name: _vm.$t(
                                            "pages.consumers.cart.flat_price"
                                          ),
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.mutableValue.flat_price,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.mutableValue,
                                              "flat_price",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "mutableValue.flat_price",
                                        },
                                      }),
                                      _c(
                                        "p",
                                        { staticClass: "inline-block ml-2" },
                                        [_vm._v("/ movie")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("section", { staticClass: "text-left" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border border-brown-grey py-1 px-3 mb-2",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "block w-full h-10 pl-2 flex items-center cursor-pointer",
                              attrs: { for: "revenueShare" },
                            },
                            [
                              _c(
                                "FlRadio",
                                {
                                  staticClass: "inline-block mr-2",
                                  attrs: {
                                    inputId: "revenueShare",
                                    inputFieldName: "pricing_type",
                                    fieldValue: 2,
                                  },
                                  model: {
                                    value: _vm.mutableValue.pricing_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.mutableValue,
                                        "pricing_type",
                                        $$v
                                      )
                                    },
                                    expression: "mutableValue.pricing_type",
                                  },
                                },
                                [_vm._v("By revenue share")]
                              ),
                            ],
                            1
                          ),
                          _vm.value.pricing_type == 2
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-light-grey w-full text-xs p-4",
                                },
                                [
                                  _c("div", { staticClass: "mb-4" }, [
                                    _c("span", [
                                      _vm._v(
                                        "Select this if you wish to pay by sharing the revenue earned. "
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "mb-4" }, [
                                    _c("span", [
                                      _vm._v(
                                        "Share of revenue licensee (buyer) shall pay to licensor (seller) for a single movie:"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("FlInlineInput", {
                                        class: "inline-block",
                                        attrs: {
                                          fieldId: "revenue_percent",
                                          rules: {
                                            required: _vm.isRevenueShare,
                                            min_value: 0.0001,
                                          },
                                          rounded: 4,
                                          type: "decimal",
                                          name: _vm.$t(
                                            "pages.consumers.cart.revenue_percent"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.mutableValue.revenue_percent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.mutableValue,
                                              "revenue_percent",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "mutableValue.revenue_percent",
                                        },
                                      }),
                                      _c("span", { staticClass: "mx-2" }, [
                                        _vm._v("％"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "mb-4" }, [
                                    _c(
                                      "span",
                                      { staticClass: "bg-off-yellow-lighter" },
                                      [
                                        _vm._v(
                                          "Licensor tends not to send counter offer when licensor revenue share is less than 50%."
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "mb-4" }, [
                                    _c("span", [_vm._v("Estimated* ")]),
                                    _c("span", [
                                      _vm._v(
                                        "net revenue per month for a single movie:"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mb-8" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mr-2 bg-brown-grey font-bold py-2 px-4 rounded inline-block",
                                        },
                                        [_vm._v(_vm._s(_vm.currentCurrency))]
                                      ),
                                      _c("FlInlineInput", {
                                        class: "inline-block",
                                        attrs: {
                                          fieldId: "revenue_min",
                                          rules: {
                                            required: _vm.isRevenueShare,
                                            integer: true,
                                            min_value: 1,
                                          },
                                          type: "number",
                                          name: _vm.$t(
                                            "pages.consumers.cart.revenue_min"
                                          ),
                                          fitErrorMessage: true,
                                        },
                                        model: {
                                          value: _vm.mutableValue.revenue_min,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.mutableValue,
                                              "revenue_min",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "mutableValue.revenue_min",
                                        },
                                      }),
                                      _c("span", { staticClass: "mx-2" }, [
                                        _vm._v("/ month to"),
                                      ]),
                                      _c("FlInlineInput", {
                                        class: "inline-block",
                                        attrs: {
                                          fieldId: "revenue_max",
                                          rules: {
                                            required: _vm.isRevenueShare,
                                            integer: true,
                                            min_value: 1,
                                          },
                                          type: "number",
                                          name: _vm.$t(
                                            "pages.consumers.cart.revenue_max"
                                          ),
                                          fitErrorMessage: true,
                                        },
                                        model: {
                                          value: _vm.mutableValue.revenue_max,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.mutableValue,
                                              "revenue_max",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "mutableValue.revenue_max",
                                        },
                                      }),
                                      _c("span", { staticClass: "mx-2" }, [
                                        _vm._v("/ month"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "mb-4 text-gray-600" },
                                    [
                                      _vm._v(
                                        "* This is a non-binding estimate and is for indicitive purpose only."
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "mb-4 pl-2" }, [
                                    _c("p", { staticClass: "mb-4" }, [
                                      _vm._v(
                                        "The estimated monthly charge by revenue for a single movie is between:"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "mb-4" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-center w-40 bg-gray-400 rounded py-2 inline-block",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              (_vm.value.revenue_percent *
                                                _vm.value.revenue_min) /
                                                100
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("span", { staticClass: "px-2" }, [
                                        _vm._v("/ month and"),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-center w-40 bg-gray-400 rounded py-2 inline-block",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              (_vm.value.revenue_percent *
                                                _vm.value.revenue_max) /
                                                100
                                            )
                                          ),
                                        ]
                                      ),
                                      _c("span", { staticClass: "mx-2" }, [
                                        _vm._v("/ month"),
                                      ]),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "mb-4 text-gray-600" },
                                      [
                                        _vm._v(
                                          "* This is a non-binding estimate and is for indicitive purpose only."
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "FlCheckbox",
                                        {
                                          model: {
                                            value: _vm.hasMinimumGuarantee,
                                            callback: function ($$v) {
                                              _vm.hasMinimumGuarantee = $$v
                                            },
                                            expression: "hasMinimumGuarantee",
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "mb-4" }, [
                                            _vm._v(
                                              "Licensee (Buyer) offers a Minimum Guarantee for a single movie of:"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "mr-2 bg-brown-grey font-bold py-2 px-4 rounded inline-block",
                                        },
                                        [_vm._v(_vm._s(_vm.currentCurrency))]
                                      ),
                                      _c("FlInlineInput", {
                                        class: "inline-block",
                                        attrs: {
                                          fieldId: "price_minimum_guarantee",
                                          disabled: !_vm.hasMinimumGuarantee,
                                          rules: {
                                            required:
                                              _vm.isRevenueShare &&
                                              _vm.hasMinimumGuarantee,
                                            integer: true,
                                            min_value: _vm.hasMinimumGuarantee
                                              ? 1
                                              : 0,
                                          },
                                          type: "number",
                                          name: _vm.$t(
                                            "pages.consumers.cart.price_minimum_guarantee"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.mutableValue
                                              .price_minimum_guarantee,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.mutableValue,
                                              "price_minimum_guarantee",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "mutableValue.price_minimum_guarantee",
                                        },
                                      }),
                                      _c("span", { staticClass: "mx-2" }, [
                                        _vm._v("/ movie for the/a"),
                                      ]),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          vid: "period_minimum_guarantee",
                                          rules: {
                                            required:
                                              _vm.isRevenueShare &&
                                              _vm.hasMinimumGuarantee,
                                          },
                                          name: _vm.$t(
                                            "pages.consumers.cart.period_minimum_guarantee"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.number",
                                                          value:
                                                            _vm.mutableValue
                                                              .period_minimum_guarantee,
                                                          expression:
                                                            "mutableValue.period_minimum_guarantee",
                                                          modifiers: {
                                                            number: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "input_text inline-block w-auto pr-6",
                                                      class: [
                                                        !_vm.hasMinimumGuarantee
                                                          ? "bg-light-grey"
                                                          : "",
                                                      ],
                                                      attrs: {
                                                        disabled:
                                                          !_vm.hasMinimumGuarantee,
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return _vm._n(
                                                                  val
                                                                )
                                                              })
                                                          _vm.$set(
                                                            _vm.mutableValue,
                                                            "period_minimum_guarantee",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          domProps: {
                                                            value: 1,
                                                          },
                                                        },
                                                        [_vm._v("Month")]
                                                      ),
                                                      _c(
                                                        "option",
                                                        {
                                                          domProps: {
                                                            value: 2,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Contract period"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors.length,
                                                          expression:
                                                            "errors.length",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "text-xxs text-left text-red-600 absolute",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Channels", {
        attrs: { disabled: _vm.newChannelDisabled },
        model: {
          value: _vm.mutableValue.channels,
          callback: function ($$v) {
            _vm.$set(_vm.mutableValue, "channels", $$v)
          },
          expression: "mutableValue.channels",
        },
      }),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(
          _vm._s("Step7: " + _vm.$t("pages.commons.quotes.offline_viewing"))
        ),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(
          _vm._s(_vm.$t("pages.consumers.cart.offline_viewing_description_1"))
        ),
      ]),
      _c("div", { staticClass: "mb-8 ml-4" }, [
        _c("div", { staticClass: "grid grid-cols-12 gap-x-6" }, [
          _c("p", { staticClass: "col-span-12 text-sm mb-4" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("pages.consumers.cart.offline"))),
            ]),
            _c("span", [
              _vm._v(_vm._s(_vm.$t("pages.consumers.cart.offline_complement"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "col-span-8 text-left inline-block" },
            [
              _c(
                "FlCheckbox",
                {
                  model: {
                    value: _vm.mutableValue.allow_offline_viewing,
                    callback: function ($$v) {
                      _vm.$set(_vm.mutableValue, "allow_offline_viewing", $$v)
                    },
                    expression: "mutableValue.allow_offline_viewing",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "sheets.delivery_terms.columns.allow_offline_viewing"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.delivery_method"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(
          _vm._s(_vm.$t("pages.consumers.cart.delivery_method_description_1"))
        ),
      ]),
      _c(
        "div",
        { staticClass: "mb-8 ml-4" },
        [
          _c("ValidationProvider", {
            attrs: {
              vid: "delivery_method",
              rules: { required: true },
              name: _vm.$t("pages.consumers.cart.delivery_method"),
              slim: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: errors.length,
                            expression: "errors.length",
                          },
                        ],
                        staticClass:
                          "text-xs font-bold text-left text-red-600 mb-2",
                      },
                      [_vm._v(_vm._s(errors[0]))]
                    ),
                    _c("div", { staticClass: "mb-2 text-left" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border border-brown-grey flex items-center justify-between px-2 h-10",
                        },
                        [
                          _c(
                            "div",
                            {},
                            [
                              _c(
                                "FlRadio",
                                {
                                  attrs: {
                                    inputFieldName: "delivery_method",
                                    fieldValue: 1,
                                  },
                                  model: {
                                    value: _vm.mutableValue.delivery_method,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.mutableValue,
                                        "delivery_method",
                                        $$v
                                      )
                                    },
                                    expression: "mutableValue.delivery_method",
                                  },
                                },
                                [_vm._v("Filmination File Transfer (online)")]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v("+ $0.00USD"),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("div", { staticClass: "mb-2 text-left" }, [
            _c(
              "label",
              {
                staticClass:
                  "block cursor-pointer border border-brown-grey flex items-center justify-between px-2 h-10",
                attrs: { for: "dropbox" },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "FlRadio",
                      {
                        attrs: {
                          inputFieldName: "delivery_method",
                          fieldValue: 5,
                        },
                        model: {
                          value: _vm.mutableValue.delivery_method,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "delivery_method", $$v)
                          },
                          expression: "mutableValue.delivery_method",
                        },
                      },
                      [_vm._v("Google Drive")]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "font-bold" }, [_vm._v("+ $100.00USD")]),
              ]
            ),
            _vm.value.delivery_method == 5
              ? _c("div", { staticClass: "bg-light-grey pb-1" }, [
                  _c("p", { staticClass: "ml-4 py-2" }, [
                    _vm._v("Please enter google drive url."),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "dropbox_url",
                          rules: { required: true, url: true },
                          name: "url",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Google Drive url")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.dropbox_url,
                                        expression: "mutableValue.dropbox_url",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.dropbox_url,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "dropbox_url",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 ml-2 text-xxs text-left text-red-600",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3172153412
                        ),
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "mb-2 text-left" }, [
            _c(
              "label",
              {
                staticClass:
                  "block cursor-pointer border border-brown-grey flex items-center justify-between px-2 h-10",
                attrs: { for: "ftp" },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "FlRadio",
                      {
                        attrs: {
                          inputFieldName: "delivery_method",
                          fieldValue: 2,
                        },
                        model: {
                          value: _vm.mutableValue.delivery_method,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "delivery_method", $$v)
                          },
                          expression: "mutableValue.delivery_method",
                        },
                      },
                      [_vm._v("Designated File Transfer Server")]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "font-bold" }, [
                  _vm._v("+ $100.00 USD"),
                ]),
              ]
            ),
            _vm.value.delivery_method == 2
              ? _c("div", { staticClass: "bg-light-grey pb-1" }, [
                  _c("p", { staticClass: "ml-4 py-2" }, [
                    _vm._v("Please enter the delivery address."),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 col-gap-6 mb-6",
                        attrs: {
                          vid: "ftp_server_address",
                          rules: { required: true },
                          name: "Server address",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Server address")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.mutableValue.ftp_server_address,
                                        expression:
                                          "mutableValue.ftp_server_address",
                                      },
                                    ],
                                    staticClass: "col-span-7 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.mutableValue.ftp_server_address,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "ftp_server_address",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            errors.length &&
                                            _vm.value.channels.length === 0,
                                          expression:
                                            "errors.length && value.channels.length === 0",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          822801714
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 col-gap-6 mb-6",
                        attrs: {
                          vid: "ftp_username",
                          rules: { required: true },
                          name: "User name",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("User name")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.ftp_username,
                                        expression: "mutableValue.ftp_username",
                                      },
                                    ],
                                    staticClass: "col-span-7 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.ftp_username,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "ftp_username",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            errors.length &&
                                            _vm.value.channels.length === 0,
                                          expression:
                                            "errors.length && value.channels.length === 0",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1508106231
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 col-gap-6 mb-6",
                        attrs: {
                          vid: "ftp_password",
                          rules: { required: true },
                          name: "Password",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Password")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.ftp_password,
                                        expression: "mutableValue.ftp_password",
                                      },
                                    ],
                                    staticClass: "col-span-7 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.ftp_password,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "ftp_password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            errors.length &&
                                            _vm.value.channels.length === 0,
                                          expression:
                                            "errors.length && value.channels.length === 0",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1252095742
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 col-gap-6 mb-4",
                        attrs: {
                          vid: "ftp_filepath",
                          rules: { required: true },
                          name: "File path",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("File path")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.ftp_filepath,
                                        expression: "mutableValue.ftp_filepath",
                                      },
                                    ],
                                    staticClass: "col-span-7 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.ftp_filepath,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "ftp_filepath",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            errors.length &&
                                            _vm.value.channels.length === 0,
                                          expression:
                                            "errors.length && value.channels.length === 0",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3974406890
                        ),
                      }),
                      _c(
                        "div",
                        { staticClass: "grid grid-cols-10 col-gap-6 mb-6" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "col-span-2 text-right font-bold flex items-center justify-end",
                            },
                            [_vm._v("Trial period")]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-3" },
                            [
                              _c("FlDate2", {
                                attrs: {
                                  fieldId: "ftp_trial_start",
                                  sublabel: "start",
                                  fromDate: new Date(),
                                  toDate: _vm.value.ftp_trial_end,
                                  bgClass: "bg-transparent",
                                  rules: { required: true },
                                },
                                model: {
                                  value: _vm.mutableValue.ftp_trial_start,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mutableValue,
                                      "ftp_trial_start",
                                      $$v
                                    )
                                  },
                                  expression: "mutableValue.ftp_trial_start",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-start-6 col-span-3 relative" },
                            [
                              _c("FlDate2", {
                                attrs: {
                                  fieldId: "ftp_trial_end",
                                  sublabel: "end",
                                  fromDate: _vm.value.ftp_trial_start,
                                  bgClass: "bg-transparent",
                                  fieldName: "Trial period",
                                  rules: {
                                    required: true,
                                    dateAfter: { baseDate: new Date() },
                                  },
                                },
                                model: {
                                  value: _vm.mutableValue.ftp_trial_end,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.mutableValue,
                                      "ftp_trial_end",
                                      $$v
                                    )
                                  },
                                  expression: "mutableValue.ftp_trial_end",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "mb-2 text-left" }, [
            _c(
              "label",
              {
                staticClass:
                  "block cursor-pointer border border-brown-grey flex items-center justify-between px-2 h-10",
                attrs: { for: "ems" },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "FlRadio",
                      {
                        attrs: {
                          inputFieldName: "delivery_method",
                          fieldValue: 3,
                        },
                        model: {
                          value: _vm.mutableValue.delivery_method,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "delivery_method", $$v)
                          },
                          expression: "mutableValue.delivery_method",
                        },
                      },
                      [_vm._v("EMS (HDD)")]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "font-bold" }, [
                  _vm._v("+ $300.00 USD"),
                ]),
              ]
            ),
            _vm.value.delivery_method == 3
              ? _c("div", { staticClass: "bg-light-grey pb-1" }, [
                  _c("p", { staticClass: "ml-4 py-2" }, [
                    _vm._v("Please enter the delivery address."),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_country",
                          rules: { required: true },
                          name: "Country",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Country")]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.mutableValue.hdd_country,
                                          expression:
                                            "mutableValue.hdd_country",
                                        },
                                      ],
                                      staticClass: "col-span-3 ml-2 input_text",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.mutableValue,
                                            "hdd_country",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      Object.keys(_vm.countryOptions),
                                      function (country, key) {
                                        return _c(
                                          "option",
                                          {
                                            key: key,
                                            domProps: { value: country },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.countryOptions[country]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4168552310
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_fullname",
                          rules: { required: true },
                          name: "Full Name",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Full Name")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_fullname,
                                        expression: "mutableValue.hdd_fullname",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_fullname,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_fullname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3179338859
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_street",
                          rules: { required: true },
                          name: "Street",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Street")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_street,
                                        expression: "mutableValue.hdd_street",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_street,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_street",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2925901886
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_city",
                          rules: { required: true },
                          name: "City",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("City")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_city,
                                        expression: "mutableValue.hdd_city",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_city,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_city",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          593450392
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_state",
                          rules: { required: true },
                          name: "State/Province/Rigion",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v(" State/Province/Rigion ")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_state,
                                        expression: "mutableValue.hdd_state",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_state,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_state",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3792716422
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_zip",
                          rules: { required: true },
                          name: "Zip/Postal Code",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Zip/Postal Code")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_zip,
                                        expression: "mutableValue.hdd_zip",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_zip,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_zip",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2004540395
                        ),
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "mb-2 text-left" }, [
            _c(
              "label",
              {
                staticClass:
                  "block cursor-pointer border border-brown-grey flex items-center justify-between px-2 h-10",
                attrs: { for: "fedex" },
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "FlRadio",
                      {
                        attrs: {
                          inputFieldName: "delivery_method",
                          fieldValue: 4,
                        },
                        model: {
                          value: _vm.mutableValue.delivery_method,
                          callback: function ($$v) {
                            _vm.$set(_vm.mutableValue, "delivery_method", $$v)
                          },
                          expression: "mutableValue.delivery_method",
                        },
                      },
                      [_vm._v("FedEx (HDD)")]
                    ),
                  ],
                  1
                ),
                _c("p", { staticClass: "font-bold" }, [
                  _vm._v("+ $400.00 USD"),
                ]),
              ]
            ),
            _vm.value.delivery_method == 4
              ? _c("div", { staticClass: "bg-light-grey pb-1" }, [
                  _c("p", { staticClass: "ml-4 py-2" }, [
                    _vm._v("Please enter the delivery address."),
                  ]),
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_country",
                          rules: { required: true },
                          name: "Country",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Country")]
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.mutableValue.hdd_country,
                                          expression:
                                            "mutableValue.hdd_country",
                                        },
                                      ],
                                      staticClass: "col-span-3 ml-2 input_text",
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.mutableValue,
                                            "hdd_country",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(
                                      Object.keys(_vm.countryOptions),
                                      function (country, key) {
                                        return _c(
                                          "option",
                                          {
                                            key: key,
                                            domProps: { value: country },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.countryOptions[country]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4168552310
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_fullname",
                          rules: { required: true },
                          name: "Full Name",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Full Name")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_fullname,
                                        expression: "mutableValue.hdd_fullname",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_fullname,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_fullname",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3179338859
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_street",
                          rules: { required: true },
                          name: "Street",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Street")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_street,
                                        expression: "mutableValue.hdd_street",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_street,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_street",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2925901886
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_city",
                          rules: { required: true },
                          name: "City",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("City")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_city,
                                        expression: "mutableValue.hdd_city",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_city,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_city",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          593450392
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_state",
                          rules: { required: true },
                          name: "State/Province/Rigion",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v(" State/Province/Rigion ")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_state,
                                        expression: "mutableValue.hdd_state",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_state,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_state",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3792716422
                        ),
                      }),
                      _c("ValidationProvider", {
                        staticClass: "grid grid-cols-10 mb-6",
                        attrs: {
                          vid: "hdd_zip",
                          rules: { required: true },
                          name: "Zip/Postal Code",
                          tag: "div",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-span-2 mr-2 text-right font-bold flex items-center justify-end",
                                    },
                                    [_vm._v("Zip/Postal Code")]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.mutableValue.hdd_zip,
                                        expression: "mutableValue.hdd_zip",
                                      },
                                    ],
                                    staticClass: "col-span-7 ml-2 input_text",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value: _vm.mutableValue.hdd_zip,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.mutableValue,
                                          "hdd_zip",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors.length,
                                          expression: "errors.length",
                                        },
                                      ],
                                      staticClass:
                                        "col-start-3 col-span-7 text-xs text-red-600 mt-2 ml-2",
                                    },
                                    [_vm._v(" " + _vm._s(errors[0]) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2004540395
                        ),
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(
          _vm._s(_vm.$t("pages.commons.quotes.special_note_cart_conditions"))
        ),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "pages.consumers.cart.special_note_cart_conditions_description_1"
            )
          )
        ),
      ]),
      _c(
        "div",
        { staticClass: "mb-8 ml-4" },
        [
          _c("FlTextArea", {
            attrs: { label: "message", rules: { max: _vm.maxInputMessage } },
            model: {
              value: _vm.mutableValue.contract_terms,
              callback: function ($$v) {
                _vm.$set(_vm.mutableValue, "contract_terms", $$v)
              },
              expression: "mutableValue.contract_terms",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }