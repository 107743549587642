var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-left text-sm" }, [
    _c(
      "div",
      { staticClass: "py-2 px-2 border border-gray-200 rounded" },
      [
        _vm.english
          ? _c(
              "div",
              { staticClass: "mb-4 grid grid-cols-12 gap-x-6 items-center" },
              [
                _c(
                  "FlCheckbox",
                  {
                    staticClass: "col-span-2",
                    attrs: { value: _vm.value, trueValue: _vm.english },
                    on: { input: _vm.input },
                  },
                  [_vm._v(" English ")]
                ),
                _vm.isEnglishActive && !_vm.is_voiceover && _vm.is_subtitle
                  ? _c(
                      "div",
                      { staticClass: "col-span-9" },
                      [
                        _c(
                          "label",
                          { staticClass: "block text-xs font-bold pb-2" },
                          [_vm._v("subtitle formats")]
                        ),
                        _c("FlCheckboxGroup", {
                          staticClass: "mr-4",
                          attrs: {
                            value: _vm.valuesForSubtitle(_vm.english),
                            options: _vm.subtitleFormatOptions,
                            rules: {
                              required:
                                _vm.isEnglishActive && !_vm.disableFormats,
                            },
                            direction: "horizontal",
                            disabled: _vm.disableFormats,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setValuesForSubtitle(
                                _vm.english,
                                $event
                              )
                            },
                          },
                        }),
                        _c(
                          "p",
                          { staticClass: "block pb-2 text-xs font-bold" },
                          [_vm._v("Please check all acceptable formats.")]
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "col-span-5 h-10" }),
                !_vm.isEnglishActive || _vm.is_voiceover
                  ? _c("div", { staticClass: "h-10" })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._l(_vm.otherLanguages, function (item, key) {
          return [
            _c(
              "div",
              {
                key: key,
                staticClass: "grid grid-cols-12 gap-x-6 items-center mb-4",
              },
              [
                _c("label", { staticClass: "col-span-2" }, [
                  _vm._v(_vm._s(_vm.fullLanguageName(item))),
                ]),
                _vm.is_subtitle
                  ? _c(
                      "div",
                      { staticClass: "col-span-9" },
                      [
                        _c(
                          "label",
                          { staticClass: "block text-xs font-bold pb-2" },
                          [_vm._v("subtitle formats")]
                        ),
                        _c("FlCheckboxGroup", {
                          staticClass: "mr-4",
                          attrs: {
                            value: _vm.valuesForSubtitle(item),
                            options: _vm.subtitleFormatOptions,
                            direction: "horizontal",
                            rules: { required: !_vm.disableFormats },
                            disabled: _vm.disableFormats,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setValuesForSubtitle(item, $event)
                            },
                          },
                        }),
                        _c(
                          "p",
                          { staticClass: "block pb-2 text-xs font-bold" },
                          [_vm._v("Please check all acceptable formats.")]
                        ),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "col-span-9 h-10" }),
                _c(
                  "button",
                  {
                    staticClass:
                      "col-span-1 py-1 bg-base-yellow font-bold h-10 text-center",
                    on: {
                      click: function ($event) {
                        return _vm.removeLanguage(item)
                      },
                    },
                  },
                  [_vm._v(" delete ")]
                ),
                _vm.is_subtitle
                  ? _c(
                      "div",
                      { staticClass: "col-start-1 col-span-6" },
                      [
                        _c("ValidateParts", {
                          attrs: {
                            value: item.embedded_subtitle,
                            vid: "embedded_subtitle_" + _vm.uid + "_" + key,
                            rules: { required: true },
                            name: _vm.$t(
                              "pages.commons.quotes.subtitle_file_format"
                            ),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", {
                  class: [
                    "col-span-4",
                    _vm.is_subtitle ? "col-start-8" : "col-start-1",
                  ],
                }),
              ]
            ),
          ]
        }),
        _c(
          "div",
          { staticClass: "grid grid-cols-12 gap-x-6" },
          [
            _c("FlSelect", {
              staticClass: "col-span-3",
              attrs: { options: _vm.languageOptions, unselected: true },
              model: {
                value: _vm.language,
                callback: function ($$v) {
                  _vm.language = $$v
                },
                expression: "language",
              },
            }),
            _c(
              "button",
              {
                staticClass:
                  "col-span-2 px-3 py-1 mr-2 bg-base-yellow font-bold float-right disabled:brown-grey disabled:cursor-not-allowed",
                attrs: { disabled: _vm.language.length == 0 },
                on: { click: _vm.addLanguage },
              },
              [_vm._v(" ADD ")]
            ),
            _c(
              "p",
              { staticClass: "col-span-7 text-xs" },
              [
                _vm.is_subtitle
                  ? [_vm._v("We do not have translation other than English. ")]
                  : _vm._e(),
                _vm._v(
                  " When adding new language, please select who will pay for creating the translation. "
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm.is_voiceover
          ? _c("div", { staticClass: "grid grid-cols-12 gap-6 pt-2" }, [
              _c("p", { staticClass: "col-span-12 text-justify text-xs" }, [
                _vm._v(
                  " * If no dubbed version is available, VOD or the licensor will be responsible for producing the dubbed version and bearing the cost. "
                ),
              ]),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }