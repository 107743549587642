var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "border border-brown-grey text-left relative py-1 px-3 mb-2 grid grid-cols-12 items-center",
      },
      [
        _c(
          "FlCheckbox",
          {
            staticClass: "col-span-4",
            attrs: {
              value: _vm.value,
              trueValue: _vm.rightsData,
              compareValues: ["right_type"],
            },
            on: { input: _vm.input },
          },
          [
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.data.title)),
            ]),
          ]
        ),
        _vm.isRightsSelected
          ? [
              _c("FlRadioGroup", {
                staticClass: "col-span-6",
                attrs: {
                  label: _vm.$t("pages.consumers.cart.exclusive_type"),
                  rules: { required: _vm.isRightsSelected },
                  options: _vm.exclusiveTypes,
                  name:
                    _vm.data.title +
                    " " +
                    _vm.$t("pages.consumers.cart.exclusive_type"),
                },
                model: {
                  value: _vm.rightsData.exclusive_type,
                  callback: function ($$v) {
                    _vm.$set(_vm.rightsData, "exclusive_type", $$v)
                  },
                  expression: "rightsData.exclusive_type",
                },
              }),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("FlSelect2", {
                    staticClass: "inline",
                    attrs: {
                      fieldId: "holdback_length",
                      disabled: _vm.rightsData.exclusive_type !== 3,
                      options: _vm.rightHoldbackLength,
                      hideNoError: true,
                      rules: { required: _vm.rightsData.exclusive_type === 3 },
                    },
                    model: {
                      value: _vm.rightsData.holdback_length,
                      callback: function ($$v) {
                        _vm.$set(_vm.rightsData, "holdback_length", $$v)
                      },
                      expression: "rightsData.holdback_length",
                    },
                  }),
                ],
                1
              ),
            ]
          : [_c("div", { staticClass: "h-10" })],
        _vm.data.countTitle && _vm.isRightsSelected
          ? [
              _c(
                "div",
                {
                  staticClass:
                    "col-start-0 col-span-10 h-full pb-4 flex flex-col justify-center",
                },
                [_c("span", [_vm._v(_vm._s(_vm.data.countTitle))])]
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("FlInput", {
                    attrs: {
                      type: "number",
                      rules: { required: true, min_value: 1 },
                    },
                    model: {
                      value: _vm.rightsData.air_run_count,
                      callback: function ($$v) {
                        _vm.$set(_vm.rightsData, "air_run_count", _vm._n($$v))
                      },
                      expression: "rightsData.air_run_count",
                    },
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }