var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto mb-6" },
    [
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.artworks"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.artworks_description"))),
      ]),
      _c("CartGlossary", { attrs: { genreId: "648025fb8cb2e6b0511bb764" } }),
      _c(
        "div",
        { staticClass: "ml-4 mb-8" },
        [
          _c(
            "FlAccordion",
            {
              attrs: {
                enableTitleBarToggle: false,
                enableRightButtonToggle: false,
                defaultOpen: _vm.hasScreenshot,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (ref) {
                    var toggleState = ref.toggleState
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          on: {
                            input: function ($event) {
                              return toggleState(_vm.hasScreenshot)
                            },
                          },
                          model: {
                            value: _vm.hasScreenshot,
                            callback: function ($$v) {
                              _vm.hasScreenshot = $$v
                            },
                            expression: "hasScreenshot",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("commons.fields.screenshot"))),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              [
                _c("ImageFormat", {
                  attrs: {
                    prefix: "screenshot",
                    required: _vm.hasScreenshot,
                    title: _vm.$t("commons.fields.screenshot"),
                  },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ml-4 mb-8" },
        [
          _c(
            "FlAccordion",
            {
              attrs: {
                enableTitleBarToggle: false,
                enableRightButtonToggle: false,
                defaultOpen: _vm.hasPoster,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (ref) {
                    var toggleState = ref.toggleState
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          on: {
                            input: function ($event) {
                              return toggleState(_vm.hasPoster)
                            },
                          },
                          model: {
                            value: _vm.hasPoster,
                            callback: function ($$v) {
                              _vm.hasPoster = $$v
                            },
                            expression: "hasPoster",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("commons.fields.poster"))),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              [
                _c("ImageFormat", {
                  attrs: {
                    prefix: "poster_visual",
                    required: _vm.hasPoster,
                    title: _vm.$t("commons.fields.poster"),
                  },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ml-4 mb-8" },
        [
          _c(
            "FlAccordion",
            {
              attrs: {
                enableTitleBarToggle: false,
                enableRightButtonToggle: false,
                defaultOpen: _vm.hasLogo,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (ref) {
                    var toggleState = ref.toggleState
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          on: {
                            input: function ($event) {
                              return toggleState(_vm.hasLogo)
                            },
                          },
                          model: {
                            value: _vm.hasLogo,
                            callback: function ($$v) {
                              _vm.hasLogo = $$v
                            },
                            expression: "hasLogo",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(
                              _vm._s(_vm.$t("commons.fields.logo_visual"))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              [
                _c("ImageFormat", {
                  attrs: {
                    prefix: "logo_visual",
                    required: _vm.hasLogo,
                    hasLanguage: false,
                    title: _vm.$t("commons.fields.logo_visual"),
                  },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ml-4 mb-8" },
        [
          _c(
            "FlAccordion",
            {
              attrs: {
                enableTitleBarToggle: false,
                enableRightButtonToggle: false,
                defaultOpen: _vm.hasKeyArt,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (ref) {
                    var toggleState = ref.toggleState
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          on: {
                            input: function ($event) {
                              return toggleState(_vm.hasKeyArt)
                            },
                          },
                          model: {
                            value: _vm.hasKeyArt,
                            callback: function ($$v) {
                              _vm.hasKeyArt = $$v
                            },
                            expression: "hasKeyArt",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("commons.fields.key_art"))),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              [
                _c("ImageFormat", {
                  attrs: {
                    prefix: "key_art",
                    required: _vm.hasKeyArt,
                    title: _vm.$t("commons.fields.key_art"),
                  },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "ml-4 mb-8" }, [
        _c(
          "div",
          { staticClass: "p-2" },
          [
            _c(
              "FlCheckbox",
              {
                model: {
                  value: _vm.mutableValue.needs_font_file,
                  callback: function ($$v) {
                    _vm.$set(_vm.mutableValue, "needs_font_file", $$v)
                  },
                  expression: "mutableValue.needs_font_file",
                },
              },
              [
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("commons.fields.need_font_file"))),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "ml-4 mb-8" },
        [
          _c(
            "FlAccordion",
            {
              attrs: {
                enableTitleBarToggle: false,
                enableRightButtonToggle: false,
                defaultOpen: _vm.hasMakingStills,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (ref) {
                    var toggleState = ref.toggleState
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          on: {
                            input: function ($event) {
                              return toggleState(_vm.hasMakingStills)
                            },
                          },
                          model: {
                            value: _vm.hasMakingStills,
                            callback: function ($$v) {
                              _vm.hasMakingStills = $$v
                            },
                            expression: "hasMakingStills",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(
                              _vm._s(_vm.$t("commons.fields.making_stills"))
                            ),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              [
                _c("ImageFormat", {
                  attrs: {
                    prefix: "making_visual",
                    required: _vm.hasMakingStills,
                    title: _vm.$t("commons.fields.making_stills"),
                  },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ml-4 mb-8" },
        [
          _c(
            "FlAccordion",
            {
              attrs: {
                enableTitleBarToggle: false,
                enableRightButtonToggle: false,
                defaultOpen: _vm.hasLaurels,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (ref) {
                    var toggleState = ref.toggleState
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          on: {
                            input: function ($event) {
                              return toggleState(_vm.hasLaurels)
                            },
                          },
                          model: {
                            value: _vm.hasLaurels,
                            callback: function ($$v) {
                              _vm.hasLaurels = $$v
                            },
                            expression: "hasLaurels",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("commons.fields.laurels"))),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              [
                _c("ImageFormat", {
                  attrs: {
                    prefix: "laurels",
                    required: _vm.hasLaurels,
                    hasLanguage: false,
                    hasSize: false,
                    title: _vm.$t("commons.fields.laurels"),
                  },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ml-4 mb-8" },
        [
          _c(
            "FlAccordion",
            {
              attrs: {
                enableTitleBarToggle: false,
                enableRightButtonToggle: false,
                defaultOpen: _vm.hasBanner,
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (ref) {
                    var toggleState = ref.toggleState
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          on: {
                            input: function ($event) {
                              return toggleState(_vm.hasBanner)
                            },
                          },
                          model: {
                            value: _vm.hasBanner,
                            callback: function ($$v) {
                              _vm.hasBanner = $$v
                            },
                            expression: "hasBanner",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("commons.fields.banner"))),
                          ]),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              [
                _c("ImageFormat", {
                  attrs: {
                    prefix: "banner_imagery",
                    required: _vm.hasBanner,
                    title: _vm.$t("commons.fields.banner"),
                  },
                  model: {
                    value: _vm.mutableValue,
                    callback: function ($$v) {
                      _vm.mutableValue = $$v
                    },
                    expression: "mutableValue",
                  },
                }),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }