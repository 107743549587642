var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-full grid grid-cols-12 gap-6 border border-borwn-grey hover:bg-gray-300",
      on: { click: _vm.gotoMovie },
    },
    [
      _c("FlMovieImage", {
        staticClass: "col-span-2",
        attrs: { value: _vm.item.movie.featured_images[0].file.url },
      }),
      _c(
        "div",
        {
          staticClass:
            "col-span-9 overflow-hidden text-left flex flex-col justify-center",
        },
        [
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.item.movie.film_name || "Loading...")),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "col-span-1 float-right flex flex-col justify-center" },
        [
          _c(
            "button",
            {
              staticClass: "relative block mr-4",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.deleteItemFromCart(_vm.item)
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/image/trash_icon_grey.svg") },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }