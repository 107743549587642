var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-2 border border-gray-200 rounded" },
    [
      _vm._t("default"),
      _vm.enableDcpFormat
        ? _c(
            "div",
            { staticClass: "w-full mb-4 text-sm" },
            [
              _c(
                "FlCheckbox",
                {
                  attrs: { trueValue: true },
                  model: {
                    value: _vm.requestDcp,
                    callback: function ($$v) {
                      _vm.requestDcp = $$v
                    },
                    expression: "requestDcp",
                  },
                },
                [_vm._v("Digital Cinema Package")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "flex space-x-4" }, [
        _c(
          "div",
          { staticClass: "flex-auto text-left" },
          [
            _c("label", { staticClass: "block text-xs font-bold pb-2" }, [
              _vm._v("Video Size"),
            ]),
            _c("FlCheckboxGroup", {
              staticClass: "text-sm",
              attrs: {
                disabled: _vm.requestDcp,
                options: _vm.movieResolution,
                rules: { required: !_vm.requestDcp, min: 1 },
                name: "video size",
              },
              model: {
                value: _vm.mutableValue.resolutions,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableValue, "resolutions", $$v)
                },
                expression: "mutableValue.resolutions",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-auto text-left" },
          [
            _c("label", { staticClass: "block text-xs font-bold pb-2" }, [
              _vm._v("Framerate"),
            ]),
            _c("FlCheckboxGroup", {
              staticClass: "text-sm",
              attrs: {
                disabled: _vm.requestDcp,
                options: _vm.frameRate,
                rules: { required: !_vm.requestDcp, min: 1 },
                name: "frame rate",
              },
              model: {
                value: _vm.mutableValue.frame_rates,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableValue, "frame_rates", $$v)
                },
                expression: "mutableValue.frame_rates",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-auto text-left" },
          [
            _c("label", { staticClass: "block text-xs font-bold pb-2" }, [
              _vm._v("Aspect Ratio"),
            ]),
            _c("FlCheckboxGroup", {
              staticClass: "text-sm",
              attrs: {
                disabled: _vm.requestDcp,
                options: _vm.aspectRatio,
                rules: { required: !_vm.requestDcp, min: 1 },
                name: "aspect ratio",
              },
              model: {
                value: _vm.mutableValue.aspect_ratios,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableValue, "aspect_ratios", $$v)
                },
                expression: "mutableValue.aspect_ratios",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-auto text-left" },
          [
            _c("label", { staticClass: "block text-xs font-bold pb-2" }, [
              _vm._v("Video Format"),
            ]),
            _c("FlCheckboxGroup", {
              staticClass: "text-sm",
              attrs: {
                disabled: _vm.requestDcp,
                options: _vm.videoFormat.filter(function (option) {
                  return option.value !== 4
                }),
                rules: { required: !_vm.requestDcp, min: 1 },
                name: "video format",
              },
              model: {
                value: _vm.mutableValue.video_encoding_formats,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableValue, "video_encoding_formats", $$v)
                },
                expression: "mutableValue.video_encoding_formats",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-auto text-left" },
          [
            _c("label", { staticClass: "block text-xs font-bold pb-2" }, [
              _vm._v("Audio Mode"),
            ]),
            _c("FlCheckboxGroup", {
              staticClass: "text-sm",
              attrs: {
                disabled: _vm.requestDcp,
                options: _vm.audioFormat,
                rules: { required: !_vm.requestDcp, min: 1 },
                name: "audio mode",
              },
              model: {
                value: _vm.mutableValue.audio_formats,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableValue, "audio_formats", $$v)
                },
                expression: "mutableValue.audio_formats",
              },
            }),
          ],
          1
        ),
      ]),
      _c("p", { staticClass: "w-full text-xs font-bold" }, [
        _vm._v("Please check all acceptable formats."),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }