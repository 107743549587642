var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "w-full text-center relative disabled:bg-very-light-grey disabled:cursor-not-allowed",
      class: {
        "h-6": _vm.size === "small",
        "font-bold h-10": _vm.size === "normal",
        "font-bold h-15 text-lg": _vm.size === "large",
        primary: _vm.type === "primary",
      },
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.onClick },
    },
    [
      _vm.state === "error"
        ? _c("img", {
            staticClass: "state",
            class: {
              "ml-1 h-4": _vm.size === "small",
              "ml-3 h-5": _vm.size === "normal",
              "ml-4 h-6": _vm.size === "large",
            },
            attrs: { src: require("../assets/image/circle_cross.svg") },
          })
        : _vm._e(),
      _vm.state === "success"
        ? _c("img", {
            staticClass: "state",
            class: {
              "ml-1 h-4": _vm.size === "small",
              "ml-3 h-5": _vm.size === "normal",
              "ml-4 h-6": _vm.size === "large",
            },
            attrs: { src: require("../assets/image/circle_check.svg") },
          })
        : _vm._e(),
      _vm.state === "processing"
        ? _c("img", {
            staticClass: "state animate-spin-slow",
            class: {
              "ml-1 h-4": _vm.size === "small",
              "ml-3 h-5": _vm.size === "normal",
              "ml-4 h-6": _vm.size === "large",
            },
            attrs: { src: require("../assets/image/cinema_real.svg") },
          })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.label) + " "),
      _vm.hasArrow
        ? _c("img", {
            staticClass: "absolute bottom-0 right-0 top-0 my-auto",
            class: {
              "mr-1 h-3": _vm.size === "small",
              "mr-2 h-4": _vm.size === "normal",
              "mr-3 h-5": _vm.size === "large",
            },
            attrs: { src: require("../assets/image/iconmonstr-arrow-25.svg") },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }