var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto mb-6" },
    [
      _c("h2", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.documents"))),
      ]),
      _c("p", { staticClass: "mb-6 text-sm" }, [
        _vm._v(_vm._s(_vm.$t("pages.consumers.cart.document_description"))),
      ]),
      _c("CartGlossary", { attrs: { genreId: "647998b222afc19d61e6b923" } }),
      _vm._l(_vm.documentTypes, function (type, index) {
        return _c(
          "div",
          { key: index, staticClass: "ml-4 mb-8" },
          [
            _c(
              "FlAccordion",
              {
                attrs: {
                  enableTitleBarToggle: false,
                  enableRightButtonToggle: false,
                  defaultOpen:
                    _vm.documentFormatsOfType(type.type) &&
                    _vm.documentFormatsOfType(type.type).length > 0,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (ref) {
                        var toggleState = ref.toggleState
                        return [
                          _c(
                            "FlCheckbox",
                            {
                              attrs: {
                                value:
                                  _vm.documentFormatsOfType(type.type) &&
                                  _vm.documentFormatsOfType(type.type).length >
                                    0,
                              },
                              on: {
                                input: function ($event) {
                                  _vm.setDocumentOfType(type.type, $event),
                                    toggleState($event)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "font-bold" }, [
                                _vm._v(_vm._s(type.name)),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                [
                  _c("FlCheckboxGroup", {
                    attrs: {
                      direction: "horizontal",
                      options: _vm.formatsForType(type.type),
                      value: _vm.documentFormatsOfType(type.type),
                      translateText: false,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setValueToFormatsOfType(type.type, $event)
                      },
                    },
                  }),
                  _c("p", { staticClass: "block pb-2 text-xs font-bold" }, [
                    _vm._v("Please check all acceptable formats."),
                  ]),
                ],
              ],
              2
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }