var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rounded bg-light-grey mb-8 p-4" },
    [
      _c("h3", { staticClass: "text-lg mb-4" }, [
        _vm._v(_vm._s(_vm.$t("components.pulldownModal.help.dictionary"))),
      ]),
      _vm._l(_vm.glossaries, function (item, index) {
        return [
          _c("h4", { key: "title-" + index, staticClass: "text-base" }, [
            _vm._v(_vm._s(item.word)),
          ]),
          _c("div", {
            key: "description-" + index,
            staticClass: "text-sm text-justify ml-4 mb-2",
            domProps: { innerHTML: _vm._s(item.description) },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }